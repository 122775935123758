<script>
import { mapActions, mapMutations } from 'vuex';

import {
  SET_HEADER_TITLE,
  SET_ACTIVE_NAV_LINK,
} from '@/store/header/mutations';
import { LOAD_NAV_TABS } from '@/store/funnels/actions';

export default {
  name: 'EzfFunnelsWrapper',
  methods: {
    ...mapActions('funnels', [LOAD_NAV_TABS]),
    ...mapMutations('header', [SET_HEADER_TITLE, SET_ACTIVE_NAV_LINK]),
  },
  created() {
    this[SET_HEADER_TITLE]([{ name: 'navbar.funnels' }]);
    this[SET_ACTIVE_NAV_LINK]('funnels');
    this[LOAD_NAV_TABS]();
  },
};
</script>

<template>
  <router-view></router-view>
</template>

<style>
.dots-button {
  min-width: 24px !important;
  min-height: 24px !important;
  width: 24px;
  height: 24px;
}
</style>
